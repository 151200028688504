import React, { Fragment } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Card from '@components/Card';
import Input from '@components/Input';
import Alert from '@components/Alert';
import { makePostRequest } from '@helpers/requests';
import { CONTACT } from '@helpers/api';
import { useSnackbar } from '@components/Snackbar';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import Select from '@components/Select';
import { graphql, useStaticQuery } from 'gatsby';
import Form from '@components/Form';

const ContactUsForm = () => {
    const { form } = useStaticQuery(graphql`
        query {
            form: merlinForm(_id: { eq: "5fbe93722c84360017636e0c" }) {
                _id
                name
                description
                meta {
                    updatedTimestamp
                }
                sections {
                    _id
                    title
                    description
                    multiple
                    min
                    max
                    fields {
                        label
                        hint
                        optional
                        options {
                            _id
                            label
                        }
                        validation {
                            type
                            condition
                            value
                            error
                        }
                        type
                        placeholder
                        id
                        _id
                    }
                }
            }
        }
    `);
    const [openSnackbar] = useSnackbar();

    const handleSubmit = async (values, actions) => {
        try {
            const { firstName, lastName, email, phone, plan } = values;
            const { data } = await makePostRequest(CONTACT, {
                firstName: firstName ? firstName : undefined,
                lastName: lastName ? lastName : undefined,
                email: email ? email : undefined,
                phone: phone ? phone : undefined,
                plan: plan ? plan : undefined,
            });
            actions?.setStatus({ complete: true });
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ?? 'An error occurred when attempting to submit the form.'
                );
        }
    };

    return <Form form={form} />;

    return (
        <Formik
            validationSchema={yupSchema}
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                plan: '',
                aboutUs: '',
            }}
            onSubmit={handleSubmit}
        >
            {({
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
                isSubmitting,
                handleChange,
                status,
            }) => (
                <Card>
                    <Card.Content>
                        {status?.complete ? (
                            <Alert
                                type="success"
                                message="You have successfully submit the form."
                            />
                        ) : (
                            <Fragment>
                                <Row>
                                    <Col sm={6} xs={12}>
                                        <Input
                                            label="First Name"
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                        />
                                        {touched.firstName && errors.firstName && (
                                            <Fragment>
                                                <Divider margin={2} />
                                                <Alert type="error" message={errors.firstName} />
                                            </Fragment>
                                        )}
                                        <Divider margin={2} />
                                    </Col>

                                    <Col sm={6} xs={12}>
                                        <Input
                                            label="Last Name"
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                        />
                                        {touched.lastName && errors.lastName && (
                                            <Fragment>
                                                <Divider margin={2} />
                                                <Alert type="error" message={errors.lastName} />
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                                <Divider margin={2} />
                                <Row>
                                    <Col sm={6} xs={12}>
                                        <Input
                                            label="Email"
                                            name="email"
                                            type="email"
                                            value={values.email}
                                            onChange={handleChange}
                                        />
                                        {touched.email && errors.email && (
                                            <Fragment>
                                                <Divider margin={2} />
                                                <Alert type="error" message={errors.email} />
                                            </Fragment>
                                        )}
                                        <Divider margin={2} />
                                    </Col>

                                    <Col sm={6} xs={12}>
                                        <Input
                                            label="Phone"
                                            name="phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                        />
                                        {touched.phone && errors.phone && (
                                            <Fragment>
                                                <Divider margin={2} />
                                                <Alert type="error" message={errors.phone} />
                                            </Fragment>
                                        )}
                                    </Col>
                                </Row>
                                <Divider margin={2} />
                                <Row>
                                    <Col xs={12}>
                                        <Select
                                            label="Do you have a support package in place?"
                                            name="plan"
                                            value={values.plan}
                                            onChange={handleChange}
                                        >
                                            <Select.Option label="Yes" value={true} />
                                            <Select.Option label="No" value={false} />
                                        </Select>
                                    </Col>
                                </Row>
                                <Divider margin={2} />
                                <Row>
                                    <Col sm={6} xs={12}>
                                        <Select
                                            label="How did you hear about us?"
                                            name="plan"
                                            value={values.aboutUs}
                                            onChange={handleChange}
                                        >
                                            <Select.Option label="Facebook" value={values.aboutUs} />
                                            <Select.Option label="Instagram" value={values.aboutUs} />
                                        </Select>
                                    </Col>
                                </Row>
                            </Fragment>
                        )}
                    </Card.Content>
                    <Card.Actions center>
                        <Card.Actions.Action
                            onClick={handleSubmit}
                            submitting={isSubmitting}
                            text="Start your journey"
                        />
                    </Card.Actions>
                </Card>
            )}
        </Formik>
    );
};

const yupSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name field is required'),
    lastName: Yup.string().required('Last Name field is required'),
    email: Yup.string()
        .email('Email address field must be an email address')
        .required('Email address field is required'),
    phone: Yup.number()
        .typeError('Phone field must include only numbers')
        .required('Phone number field is required'),
    plan: Yup.string().required('Plan field is required'),
    referral: Yup.string(),
});

export default ContactUsForm;

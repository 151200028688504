import React from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import { graphql } from 'gatsby';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import SEO from '@components/SEO';
import ContactUsForm from '@components/ContactForm/ContactForm';
import Header from '@components/Header';

const Contact = ({
    data: {
        page: {
            frontmatter: { title, description, information, hero_content },
        },
    },
}) => {

    return (
        <Layout>
            <SEO title={`Contact`} />
            <div className={styles.contactWrapper}>
                <div className={styles.hero}>
                    <div className={styles.heroContentWrapper}>
                        <div className={styles.heroContentContainer}>
                            <div className={styles.heroContent}>
                                <h1>{title}</h1>
                                <p>{hero_content}</p>
                            </div>
                            <div className={styles.imageContainer}>
                                <img
                                    src={'images/contact-us-people.svg'}
                                    alt={'Contact us hero image'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Grid>
                    <Row>
                        <Col>
                            <Divider margin={5} />
                            <Divider margin={5} />
                            <ContactUsForm />
                        </Col>
                    </Row>
                </Grid>

                <Divider margin={4}/>
                
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <Grid>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <div className={styles.contentImageContainer}>
                                            <img src={'images/contact-us-phone-person.svg'} className={styles.contentImage} />
                                        </div>
                                    </Col>
                                    {/*<Divider/>*/}
                                    <Col xs={12} md={8}>
                                        <div
                                            className={styles.content}
                                            dangerouslySetInnerHTML={{
                                                __html: information,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </Col>
                    </Row>
                </Grid>

                <Divider margin={4}/>

                {/*<div className={styles.additionalInformation}>*/}
                {/*    {!!information && (*/}
                {/*        <div className={styles.contentContainer}>*/}
                {/*            <div className={styles.imageContainer}>*/}
                {/*                */}
                {/*            </div>*/}
                {/*            */}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
        </Layout>
    );
};

export default Contact;

export const query = graphql`
    query contactPage($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: contact_title
                hero_content: contact_hero_content
                description: contact_description
                information: contact_information
            }
        }
    }
`;
